"use client";

import GeneralErrorPage from "@portal/components/pages/generalErrorPage";
import { GetBankColorTheme } from "@portal/helpers/getBankColorTheme";
import { ErrorInfo, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import useCenterIdOpenPages from "@portal/hooks/useCenterIdOpenPages";
import PageSpinner from "@portal/components/organisms/pageSpinner";

const OpenLayout = ({ children }: { children: React.ReactNode }) => {
  const centerId = useCenterIdOpenPages();
  const theme = GetBankColorTheme(centerId);

  return (
    <div className={`flex w-full ${theme}`}>
      <ErrorBoundary
        fallback={<GeneralErrorPage />}
        onError={(error: Error, info: ErrorInfo) => {
          console.error("ErrorBoundary", error, info);
        }}
      >
        {children}
      </ErrorBoundary>
    </div>
  );
};

const OpenLayoutWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Suspense fallback={<PageSpinner />}>
      <OpenLayout>{children}</OpenLayout>
    </Suspense>
  );
};

export default OpenLayoutWrapper;
